<template>
  <div v-if="commonCodeByOrder && gameCount" class="w1360 p010"><!-- class="mainBg" -->
    <div class="mt131">
       <transition name="fade">
         <ui-swiper />
       </transition>
    </div>
    <section class="">
      <ul id="mainGameMenu">
        <li @click="goPageByName('listcasino')">
          <img src="../assets/img/main/m-49.jpg">
          <div class="game-cate">
            <em>CASINO</em>
            <span>카지노</span>
          </div>
        </li>
        <li @click="goPageByName('listslot')">
          <img src="../assets/img/main/m-44.jpg">
          <div class="game-cate">
            <em>SLOT GAME</em>
            <span>슬롯게임</span>
          </div>
        </li>
        <li @click="goPageByName('listmini')">
          <img src="../assets/img/main/m-5.jpg">
          <div class="game-cate">
            <em>MINI GAME</em>
            <span>미니게임</span>
          </div>
        </li>
        <li @click="goPageByName('listsports')">
          <img src="../assets/img/main/m-2.jpg">
          <div class="game-cate">
            <em>SPORTS</em>
            <span>스포츠</span>
          </div>
        </li>
      </ul>
    </section>
    <div class="cs_Area">
      <div>
         <div class="cs_m">
           <h2 class="mainnames">{{ $t('front.main.livecharge') }}<a @click="goPageByName('')">+ MORE</a></h2>
           <ul class="mb50">
             <template v-for="item in cashInList" :key="item.memId">
               <li>
                 <span><img src="@/assets/img/icon_up.svg" />{{item.memId}}</span>
                 <span>{{thousand(Number(item.cashAmt))}} 원</span>
                 <span class="time"><img src="@/assets/img/icon_time.svg" />{{item.regDate}}</span>
               </li>
             </template>
             <template v-for="item in listMaxCount - cashInList.length" :key="item">
                <li>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  <span class="time">&nbsp;</span>
               </li>
             </template>
           </ul>
           <h2 class="mainnames">{{ $t('front.main.liveExchange') }}<a @click="goPageByName('')">+ MORE</a></h2>
           <ul>
             <template v-for="item in cashOutList" :key="item.memId">
               <li>
                 <span><img src="@/assets/img/icon_down.svg" />{{item.memId}}</span>
                 <span>{{thousand(Number(item.cashAmt*-1))}} 원</span>
                 <span class="time"><img src="@/assets/img/icon_time.svg" />{{item.regDate}}</span>
               </li>
             </template>
             <template v-for="item in listMaxCount - cashOutList.length" :key="item">
               <li>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  <span class="time">&nbsp;</span>
               </li>
             </template>
           </ul>
         </div>

         <div class="cs_n">
           <h2 class="mainnames" >{{ $t('front.common.notice') }}</h2>
           <ul>
             <template v-for="item in noticeList" :key="item">
                 <li @click="onClickNoticeRead(item)">
                 <span>
                    <em class="txt"><img src="@/assets/img/icon_noti.svg" />{{item.title}}</em>
                    <em class="time"><img src="@/assets/img/icon_time.svg" />{{item.regDate}}</em>
                 </span>
                 <span class="txt">{{item.content}}</span>
               </li>
             </template>
             <template v-for="item in listMaxCount - noticeList.length" :key="item">
               <li>
                 <span>
                    <em class="txt">&nbsp;</em>
                    <em class="time">&nbsp;</em>
                 </span>
                 <span class="txt">&nbsp;</span>
               </li>
             </template>
           </ul>
         </div>
      </div>
    </div>
    <div class="mainPopWrap">
      <template v-for="(item) in popupList" v-bind:key="item.bannerIdx">
        <div class="mainPop" v-if="item.delYn == 'N' && !item.isClose" :data-idx="item.bannerIdx">
          <div class="closeWrap">
            <a class="close" @click="popupCloseByCookie(item)">{{$t('front.main.today')}}</a>
            <a class="close" @click="popupClose(item)">{{$t('front.main.close')}}</a>
          </div>
          <img :src="item.bannerImg" v-if="item.bannerImg" />
          <div class="txtwrap">
            <h3>{{item.bannerTitle}}</h3>
            <ul class="popupTxt" v-if="item.bannerContent">
              <li v-html="item.bannerContent">
              </li>
            </ul>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import '@/styles/common.css'
import { getPopupCookie, setPopupCookie, tokenCheck } from '@/libs/auth-helper'

import { getMain, getMainCashStatus, getMainNotice } from '@/api/main'
import { mapState } from 'vuex'

import store from '@/store'
import UiSwiper from '@/components/ui/UiSwiper.vue'
import { thousand } from '../libs/utils'

export default {
  name: 'Index.vue',
  components: {
    UiSwiper
  },
  created () {
    // this.emitter.emit('Loading', true)
    // this.emitter.emit('Loading', true)
    // setTimeout(() => {
    //
    // }, 5000)
    // setTimeout(() => {
    //   this.emitter.emit('Loading', false)
    // }, 8000)
    this.loadMain()
    this.popupMerge()
    this.loadNotice()
    this.loadCashStatus()
  },
  data () {
    return {
      currentTab: 'tab-1',
      popupList: {},
      cashInList: [],
      cashOutList: [],
      noticeList: [],
      listMaxCount: 5,
      noticeListMaxCount: 5
    }
  },
  watch: {
    popup () {
      this.popupMerge()
    },
    popupLogin () {
      this.popupMerge()
    },
    async userData () {
      await store.dispatch('storePopup')
      if (this.userData) {
        await store.dispatch('storePopupLogin')
      }
      await this.popupMerge()
    }
  },
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByOrder',
      'popup',
      'popupLogin',
      'banner',
      'gameCount'
    ])

  },
  methods: {
    onClickNoticeRead (item) {
      console.log(item)
      this.$router.push({ name: 'noticeRead', params: { boardIdx: item.boardIdx } })
    },
    thousand,
    loadCashStatus () {
      getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList.slice(0, this.listMaxCount)
          this.cashOutList = result.data.cashOutList.slice(0, this.listMaxCount)
        }
      })
    },
    loadNotice () {
      getMainNotice({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result)
          this.noticeList = result.data.boardList.slice(0, this.noticeListMaxCount)
        }
      })
    },
    getBackgroundImage (item) {
      try {
        const image = require('../assets/img/game_main_pc_' + item.code + '_off.png')
        const imageOff = require('../assets/img/game_main_pc_' + item.code + '_off.png')
        if (image && imageOff) {
          if (item.isHover) {
            return { backgroundImage: 'url(' + image + ')' }
          } else {
            return { backgroundImage: 'url(' + imageOff + ')' }
          }
        } else {
          return { backgroundImage: 'url()' }
        }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    getBackgroundLogoImage (item) {
      try {
        const image = require('../assets/img/glogo' + item.code + '.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    popupMerge () {
      const popupLogin = this.userData ? this.popupLogin : []
      const pList = this.popup.concat(popupLogin)

      this.popupList = {}

      pList.forEach(item => {
        if (!item.isClose) {
          const key = item.bannerIdx
          const siteId = process.env.VUE_APP_SITE_ID || 'moka'
          const name = siteId + '_popup_' + item.bannerIdx
          const cookie = getPopupCookie(name)
          if (cookie) {
            item.isClose = true
          } else {
            item.isClose = false
          }
          if (!this.popupList[key]) {
            this.popupList[key] = item
          }
        }
      })
    },
    popupClose (item) {
      item.isClose = true
    },
    popupCloseByCookie (item) {
      item.isClose = true
      setPopupCookie(item)
    },
    slotOpen (event, groupCode, codeName, code) {
      console.log(event, { groupCode, codeName, code })
      this.emitter.emit(event, { groupCode, codeName, code })
      this.emitter.emit('Loading', false)
    },
    loadMain () {
      if (this.userData) {
        getMain({}).then(res => {
          // console.log(res)
        })
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    await tokenCheck()
    // if (result) {
    next()
    // }
  }
}
</script>
<style>
.mainSlide img{width:100%;}
.mainSlide .swiper-slide>a{width:100%;display:block;}
.mainSlide .swiper-slide>a img{display:block;width:100%;height: ;object-fit:cover;object-position:center;}
.swiper-button-next, .swiper-button-prev {bottom: 50px;top: auto;}
.swiper-button-next {
  background-image: url(../assets/img/slideright.png) !important;
  background-size: 11px 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  right: 50%;
  bottom: 3px;
  margin-right: -640px;
  z-index: 11;
}
.swiper-button-prev {
  background-image: url(../assets/img/slideleft.png) !important;
  background-size: 11px 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  left: 50%;
  bottom: 3px;
  margin-left: -640px;
  z-index: 11;
}
.swiper-button-next:after, .swiper-button-prev:after {content: '';}
.swiper-button-next:hover, .swiper-button-prev:hover {opacity: 1;}
.swiper-pagination {bottom: 0 !important;gap: 100px;background-color: ;height: 50px;display: flex;justify-content: center;align-items: center;}
.swiper-pagination-bullet {width: 105px; min-height: 19px; background: transparent; opacity: 0.3; color: #fff;}
.swiper-pagination-bullet-active {border-radius: 0;opacity: 1;}
</style>
<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/main.css"></style>
